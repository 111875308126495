import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

export const richTextToStyledReactComponents = (
  richText,
  overrideValues = {}
) => {
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => (
        <a
          className="text-core-rose"
          href={node?.data?.uri}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      ),
      [BLOCKS.PARAGRAPH]: (_, children) => (
        <p className="pb-6 text-xl font-normal md:text-xl text-core-grey-darkest">
          {children}
        </p>
      ),
      [BLOCKS.UL_LIST]: (_, children) => (
        <ul className="list-disc list-outside text-core-purple text-xl !mb-8 !ml-[24px]">
          {children}
        </ul>
      ),
      ...overrideValues,
    },
    // Render line breaks properley
    renderText: (text) => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
  }
  return documentToReactComponents(richText, options)
}
