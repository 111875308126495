import { SlugOrLink } from '@components/common/SlugOrLink'
import { IImage } from '@utils/contentful/contentfulImage'
import classnames from 'classnames'
import { CardImage } from './CardImage'
import { HiArrowRight } from 'react-icons/hi'
import { mapTextColorToClass } from '@components/ContentfulPage/ConfigurableText/ConfigurableText.utils'

interface ISquareCardProps extends React.HTMLProps<HTMLDivElement> {
  cardLink?: string
  backgroundColor?: string
  image?: IImage
  imageBackgroundColor?: string
  trackingIdentifier: string
  title: string
  titleColor: string
}

export const SquareCard = ({
  cardLink = '',
  backgroundColor,
  image,
  imageBackgroundColor,
  trackingIdentifier,
  title,
  titleColor,
  children,
  ...props
}: ISquareCardProps) => {
  return (
    <div
      {...props}
      className={classnames(
        'group w-[calc(100%-16px)] h-full',
        props.className ?? ''
      )}
      style={{ backgroundColor, ...(props.style || {}) }}
    >
      <SlugOrLink slugOrUrl={cardLink} trackingIdentifier={trackingIdentifier}>
        <div className="relative flex flex-col items-center justify-center text-center text-ellipsis gap-[20px]">
          <div
            className={`rounded-[32px] overflow-hidden w-full aspect-[6/5]`}
            style={{ backgroundColor: imageBackgroundColor }}
          >
            <CardImage
              src={image?.imageUrl}
              title={image?.imageTitle}
              backgroundColor={backgroundColor}
            />
          </div>

          {(title || cardLink) && (
            <div
              className={classnames(
                'absolute top-10 left-10 md:top-16 md:left-16 xl:top-24 xl:left-24',
                mapTextColorToClass[titleColor]
              )}
            >
              {title && (
                <h2
                  className={`text-3xl md:text-4xl 2xl:text-[40px] leading-snug font-mori font-semibold mb-2 mb:mb-5 xl:mb-7`}
                >
                  {title}
                </h2>
              )}

              {cardLink && (
                <div
                  className={`flex items-center text-2xl font-bold font-mori`}
                >
                  <span className="mr-4">Shop now</span>
                  <HiArrowRight />
                </div>
              )}
            </div>
          )}
        </div>
      </SlugOrLink>
    </div>
  )
}
