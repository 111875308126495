import { KeenSliderWrapper } from '@components/common/KeenSliderWrapper'
import { useDevice } from '@utils/responsiveness/useDevice'
import { CSSProperties } from 'react'
import { useInView } from 'react-intersection-observer'
import { IContentfulConfigurableSlider } from 'types/contentful/configurableSlider.types'
import ConfigurableCard from './ConfigurableCard'
import { ComponentPlaceholder } from './ComponentPlaceholder'

interface IConfigurableSliderProps extends IContentfulConfigurableSlider {
  className: string
  style: CSSProperties
  generateStatically?: boolean
}

export default function ConfigurableSlider({
  isShowArrows = false,
  loop = false,
  autoSwitchSpeed = 0,
  indicator = 'none',
  tilesInViewDesktop = 1,
  tilesInViewTablet = 1,
  tilesInViewMobile = 1,
  slides = [],
  style,
  trackingIdentifier,
  generateStatically = false,
}: IConfigurableSliderProps) {
  const { isMobile } = useDevice()
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '100px 0px',
  })

  const sliderBreakpoints = {
    '(min-width: 100px)': {
      slides: { perView: 1, spacing: 0 },
    },
    '(min-width: 280px)': {
      slides: { perView: tilesInViewMobile || 2, spacing: 0 },
    },
    '(min-width: 600px)': {
      slides: { perView: tilesInViewTablet || 4, spacing: 0 },
    },
    '(min-width: 1024px)': {
      slides: { perView: tilesInViewDesktop || 6, spacing: 0 },
    },
  }

  return (
    <div
      className={`relative w-full overflow-hidden ${
        tilesInViewDesktop > 1
          ? 'md:px-[50px]'
          : 'h-[max(calc(100vh-200px),260px)] xs:max-h-[max(min(100vh-600px,600px),260px)] sm:max-h-[max(min(100vh-540px,600px),260px)] lg:max-h-[max(min(100vw-640px,600px),260px)] xl:max-h-[max(min(100vh-400px,800px),460px)]'
      }`}
      style={style}
      ref={ref}
    >
      {generateStatically || inView ? (
        <KeenSliderWrapper
          initialConfig={{ perView: 1, spacing: 0 }}
          breakpoints={sliderBreakpoints}
          isShowArrows={isMobile ? false : isShowArrows}
          loop={slides?.length > 1 ? loop : false}
          autoSwitchSpeed={slides?.length > 1 ? autoSwitchSpeed : 0}
          indicator={indicator}
          trackingIdentifier={trackingIdentifier}
          className={`h-full ${
            tilesInViewDesktop > 1 ? '!overflow-visible ' : ''
          }`}
          hideArrowsWhenDisabled={true}
          progressIndicatorClassName={`mb-[2%]
            ${
              indicator === 'dots'
                ? 'ml-[calc(7%+24px)]'
                : 'xs:ml-[calc(7%+24px)]'
            }`}
        >
          {slides?.map((slide, index) => {
            return (
              <ConfigurableCard
                {...slide}
                className="flex items-center justify-center keen-slider__slide"
                key={`${index}-${slide.name}`}
                priorityLoadImage={index < 1}
                generateStatically={generateStatically}
                style={
                  tilesInViewDesktop ? { width: '100%', flexShrink: 0 } : {}
                }
              />
            )
          })}
        </KeenSliderWrapper>
      ) : (
        <ComponentPlaceholder height={400} />
      )}
    </div>
  )
}
