import { ContentfulNextJsImage } from '@components/common/ContentfulImage'

export const CardImage = ({
  src,
  title = '',
  backgroundColor,
  priorityLoadImage = false,
  ...props
}) => {
  if (!src) {
    return null
  }

  return (
    <div
      {...props}
      className={`relative w-full h-full ${props.className ?? ''}`}
      style={{ ...(props.style || {}) }}
    >
      <ContentfulNextJsImage
        src={src}
        alt={title}
        layout="fill"
        sizes="100vw"
        objectFit="cover"
        quality={75}
        format="jpg"
        className="transition-transform duration-300 md:group-hover:scale-105"
        backgroundColour={backgroundColor}
        priority={priorityLoadImage}
        useImageAspectRatio={true}
      />
    </div>
  )
}
