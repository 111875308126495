import { titleSizeToClass } from '@components/ContentfulPage/contentfulPage.utils'
import { IImage } from '@utils/contentful/contentfulImage'
import { THorizontalPosition, TOverlay, TVerticalPosition } from '@utils/types'
import classnames from 'classnames'
import { CardImage } from './CardImage'
import { useDevice } from '@utils/responsiveness/useDevice'
import { useRouter } from 'next/router'

export const VerticalCardTitle = ({
  titleSize = '',
  mobileTitleSize = '',
  children,
  ...props
}) => {
  const { isMobile } = useDevice()
  const titleSizeClass = isMobile
    ? titleSizeToClass[mobileTitleSize] || titleSizeToClass[titleSize]
    : titleSizeToClass[titleSize] || titleSizeToClass.XL

  return (
    <div
      {...props}
      className={classnames(
        'font-semibold',
        titleSizeClass,
        props.className ?? ''
      )}
    >
      {children}
    </div>
  )
}

export const VerticalCardSubtitle = ({ children, ...props }) => {
  return (
    <div
      {...props}
      className={classnames(
        'text-[16px] leading-[20px] font-bold',
        props.className ?? ''
      )}
    >
      {children}
    </div>
  )
}

export const VerticalCardDescription = ({ children, ...props }) => {
  return (
    <div
      {...props}
      className={classnames(
        'text-[16px] md:text-[20px] md:mb-12 leading-tight',
        props.className ?? ''
      )}
    >
      {children}
    </div>
  )
}

export const VerticalCardText = ({ children, ...props }) => {
  return (
    <div
      {...props}
      className={classnames('text-[12px] leading-tight', props.className ?? '')}
    >
      {children}
    </div>
  )
}

type TImagePosition = Omit<TVerticalPosition, 'middle'> | TOverlay
interface IVerticalCardProps extends React.HTMLProps<HTMLDivElement> {
  backgroundColor?: string
  textBlockAlign?: THorizontalPosition
  textBlockVerticalAlign?: TVerticalPosition
  image?: IImage
  imagePosition?: TImagePosition
  priorityLoadImage?: boolean
  cardLink?: string
}

const verticalImagePosition = ['top', 'bottom', 'overlay']

export const VerticalCard = ({
  backgroundColor,
  textBlockAlign = 'left',
  textBlockVerticalAlign = 'top',
  image,
  imagePosition = 'bottom',
  children,
  priorityLoadImage,
  cardLink,
  ...props
}: IVerticalCardProps) => {
  const { isMobile } = useDevice()
  const router = useRouter()

  const hasImage = !!image?.imageUrl

  let expectedImagePosition = imagePosition
  if (!verticalImagePosition.includes('' + imagePosition)) {
    expectedImagePosition = 'top'
  }

  const styles = { backgroundColor, ...(props.style || {}) }

  return (
    <div
      {...props}
      className={`relative ${props.className ?? ''}`}
      style={styles}
      onClick={() => (isMobile && cardLink ? router.push(cardLink) : () => {})}
    >
      {expectedImagePosition === 'overlay' ? (
        <>
          {hasImage && (
            <CardImage
              src={image?.imageUrl}
              title={image?.imageTitle}
              backgroundColor={backgroundColor}
              priorityLoadImage={priorityLoadImage}
            />
          )}
          <div
            className={classnames(
              hasImage ? 'absolute' : 'relative',
              'flex flex-col h-full w-full top-0 gap-[clamp(14px,0.7vw+5px,18px)] px-[36px] py-[24px]',
              textBlockAlign === 'left' && 'items-start text-left',
              textBlockAlign === 'center' && 'items-center text-center',
              textBlockAlign === 'right' && 'items-end text-right',
              textBlockVerticalAlign === 'top' && 'justify-start',
              textBlockVerticalAlign === 'middle' && 'justify-center',
              textBlockVerticalAlign === 'bottom' && 'justify-end'
            )}
          >
            {children}
          </div>
        </>
      ) : (
        <div
          className="flex flex-col w-full h-full"
          style={{
            textAlign: textBlockAlign,
          }}
        >
          {expectedImagePosition === 'top' && hasImage && (
            <CardImage
              src={image?.imageUrl}
              title={image?.imageTitle}
              backgroundColor={backgroundColor}
              priorityLoadImage={priorityLoadImage}
            />
          )}

          <div
            className={classnames(
              'flex flex-col w-ful h-full gap-[clamp(14px,0.7vw+5px,18px)] px-[24px] pt-[24px] pb-[16px]',
              textBlockAlign === 'left' && 'items-start text-left',
              textBlockAlign === 'center' && 'items-center text-center',
              textBlockAlign === 'right' && 'items-end text-right',
              textBlockVerticalAlign === 'top' && 'justify-start',
              textBlockVerticalAlign === 'middle' && 'justify-center',
              textBlockVerticalAlign === 'bottom' && 'justify-end'
            )}
          >
            {children}
          </div>
          {expectedImagePosition === 'bottom' && hasImage && (
            <CardImage
              src={image?.imageUrl}
              title={image?.imageTitle}
              backgroundColor={backgroundColor}
              priorityLoadImage={priorityLoadImage}
            />
          )}
        </div>
      )}
    </div>
  )
}
