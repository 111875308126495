import { SlugOrLink } from '@components/common/SlugOrLink'
import { IImage } from '@utils/contentful/contentfulImage'
import { TSize } from '@utils/types'
import classnames from 'classnames'
import { CardImage } from './CardImage'

export const CircleCardTitle = ({ children, ...props }) => {
  return (
    <div
      {...props}
      className={classnames(
        'text-[16px] leading-[22px] font-semibold',
        props.className ?? ''
      )}
    >
      {children}
    </div>
  )
}

interface ICircleCardProps extends React.HTMLProps<HTMLDivElement> {
  cardLink?: string
  cardSize?: TSize
  backgroundColor?: string
  image?: IImage
  imageBackgroundColor?: string
  trackingIdentifier: string
}

const CircleCardSizeMapper = {
  s: 'w-[calc(100%-36px)] md:w-[130px] h-full',
  m: 'w-[calc(100%-36px)] md:w-[178px] h-full',
  l: 'w-[calc(100%-36px)] md:w-[244px] h-full',
}

const CircleCardImageSizeMapper = {
  s: 'w-full md:w-[130px] h-full md:h-[130px] aspect-[1/1]',
  m: 'w-full md:w-[178px] h-full md:h-[178px] aspect-[1/1]',
  l: 'w-full md:w-[244px] h-full md:h-[244px] aspect-[1/1]',
}

export const CircleCard = ({
  cardSize = 's',
  cardLink = '',
  backgroundColor,
  image,
  imageBackgroundColor,
  trackingIdentifier,
  children,
  ...props
}: ICircleCardProps) => {
  return (
    <div
      {...props}
      className={classnames(
        'group',
        CircleCardSizeMapper[cardSize] || CircleCardSizeMapper.s,
        props.className ?? ''
      )}
      style={{ backgroundColor, ...(props.style || {}) }}
    >
      <SlugOrLink slugOrUrl={cardLink} trackingIdentifier={trackingIdentifier}>
        <div className="flex flex-col items-center justify-center text-center text-ellipsis gap-[20px]">
          <div
            className={`rounded-full overflow-hidden ${
              CircleCardImageSizeMapper[cardSize] || CircleCardImageSizeMapper.s
            }`}
            style={{ backgroundColor: imageBackgroundColor }}
          >
            <CardImage
              src={image?.imageUrl}
              title={image?.imageTitle}
              backgroundColor={backgroundColor}
            />
          </div>
          {children}
        </div>
      </SlugOrLink>
    </div>
  )
}
